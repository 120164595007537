import React from "react";
import Layout from "../../components/Layout";

const openingTimes = ({}) => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          {/* <div className="content"> */}
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Openingstijden
          </h1>
          <table
            style={{
              margin: "auto",
              minWidth: "222px",
            }}
            className="openingtimes table is-narrow is-striped is-hoverable"
          >
            <thead>
              <tr>
                <th>Dag</th>
                <th>Uur</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Maandag</td>
                <td>14:00 - 18:00</td>
              </tr>
              <tr>
                <td>Dinsdag</td>
                <td>14:00 - 18:00</td>
              </tr>
              <tr>
                <td>Woensdag</td>
                <td>14:00 - 18:00</td>
              </tr>
              <tr>
                <td>Donderdag</td>
                <td>14:00 - 18:00</td>
              </tr>
              <tr>
                <td>Vrijdag</td>
                <td>14:00 - 18:00</td>
              </tr>
              <tr>
                <td>Zaterdag</td>
                <td>13:00 - 18:00</td>
              </tr>
              <tr>
                <td>Zondag</td>
                <td>13:00 - 18:00</td>
              </tr>
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </section>
    </Layout>
  );
};
export default openingTimes;
